import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardMedia,
} from "@mui/material";
import {
  AccessAlarm,
  Agriculture,
  BugReportOutlined,
  PrecisionManufacturing,
} from "@mui/icons-material";
import { FaTree } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ChallengesOpportunities = () => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" marginBottom={4} sx={{ mt: 10, py: 6 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "#000000", fontWeight: 600 }}
      >
        {t("features.title")}
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "#000000", mb: 4, mt: 4 }}
      >
        {t("features.dec")}
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={5}
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0 16px 40px rgba(0,0,0,0.2)",
              },
            }}
          >
            <CardMedia
              sx={{
                height: 180,
                background: "linear-gradient(135deg, #f57c00, #ffcc80)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccessAlarm sx={{ fontSize: 60, color: "#fff" }} />
            </CardMedia>
            <CardContent sx={{ p: 3, textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                {t("features.cards.computer.title")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("features.cards.computer.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={5}
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0 16px 40px rgba(0,0,0,0.2)",
              },
            }}
          >
            <CardMedia
              sx={{
                height: 180,
                background: "linear-gradient(135deg, #4caf50, #a5d6a7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Agriculture sx={{ fontSize: 60, color: "#fff" }} />
            </CardMedia>
            <CardContent sx={{ p: 3, textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                {t("features.cards.fragmentation.title")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("features.cards.fragmentation.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={5}
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0 16px 40px rgba(0,0,0,0.2)",
              },
            }}
          >
            <CardMedia
              sx={{
                height: 180,
                background: "linear-gradient(135deg, #66bb6a, #aed581)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaTree style={{ fontSize: 60, color: "#fff" }} />
            </CardMedia>
            <CardContent sx={{ p: 3, textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                {t("features.cards.soil_degradation.title")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("features.cards.soil_degradation.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={5}
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0 16px 40px rgba(0,0,0,0.2)",
              },
            }}
          >
            <CardMedia
              sx={{
                height: 180,
                background: "linear-gradient(135deg, #ff9800, #ffcc80)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BugReportOutlined sx={{ fontSize: 60, color: "#fff" }} />
            </CardMedia>
            <CardContent sx={{ p: 3, textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                {t("features.cards.pests_diseases.title")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("features.cards.pests_diseases.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={5}
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0 16px 40px rgba(0,0,0,0.2)",
              },
            }}
          >
            <CardMedia
              sx={{
                height: 180,
                background: "linear-gradient(135deg, #3f51b5, #7986cb)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PrecisionManufacturing sx={{ fontSize: 60, color: "#fff" }} />
            </CardMedia>
            <CardContent sx={{ p: 3, textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                {t("features.cards.technology_adoption.title")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("features.cards.technology_adoption.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChallengesOpportunities;
