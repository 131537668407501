import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
  TextField,
  Snackbar,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
} from "@mui/material";
import { styled } from "@mui/system";
import recommendations from "./recommandations";
import UploadIllustration from "../image/growing-potatoes-main.jpg";
import UploadIllustration1 from "../image/tomato.webp";
import UploadIllustration3 from "../image/demo-video.mp4";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import the react-easy-crop component
import Cropper from "react-easy-crop";

// Styled components
const Container = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(4),
  backgroundColor: "#e0f7fa",
  color: "#004d40",
}));

const DropzoneContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: "center",
  backgroundColor: "#fff",
  border: "2px dashed #00796b",
  borderRadius: "12px",
  color: "#333",
  cursor: "pointer",
  margin: "auto",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    borderColor: "#004d40",
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#00796b",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#004d40",
  },
}));

const ImagePreview = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  img: {
    width: "100%",
    maxWidth: "350px",
    borderRadius: "12px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
}));

const ResultsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: "#f9f9f9",
  padding: theme.spacing(3),
  borderRadius: "12px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
}));

export default function ImageUploadSection() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [prediction, setPrediction] = useState(null);
  const [recommendation, setRecommendation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    // Check if the app is being served over HTTPS or localhost
    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      console.warn("Camera access requires HTTPS or localhost.");
      setSnackbarMessage("Camera access requires HTTPS or localhost.");
      setSnackbarOpen(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setImage(URL.createObjectURL(file));
        setPrediction(null);
        setRecommendation(null);
        setFileName(file.name);
        setCropperOpen(true); // Open the cropper
      }
    },
  });

  // Cropper states
  const [cropperOpen, setCropperOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(image, croppedAreaPixels);
      setCroppedImage(croppedImg);
      setCropperOpen(false);
      // Proceed to send cropped image for prediction
      sendImageForPrediction(croppedImg);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const sendImageForPrediction = async (croppedImg) => {
    setLoading(true);
    const formData = new FormData();

    // Convert data URL to blob
    const res = await fetch(croppedImg);
    const blob = await res.blob();
    formData.append("file", blob, "cropped_image.jpg");

    try {
      const response = await axios.post(
        "https://agrihyphen-ai.onrender.com/predict",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setPrediction(response.data);

      const diseaseClass = response.data?.class;
      const diseaseRecommendation = recommendations[diseaseClass];

      if (diseaseRecommendation) {
        setRecommendation(diseaseRecommendation.advice);
      } else {
        setRecommendation([
          "No recommendations for this picture. If it's a potato or tomato leaf, consider retaking the picture with a clear, full display.",
        ]);
      }

      setSnackbarMessage("Image uploaded successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error uploading the image:", error);
      setSnackbarMessage("Error uploading the image.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setPrediction(null);
      setRecommendation(null);
      setFileName(file.name);
      setCropperOpen(true); // Open the cropper
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleBackToHome = () => {
    navigate("/");
  };

  // Handle image capture from camera input
  const handleCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setPrediction(null);
      setRecommendation(null);
      setFileName("captured_image.jpg");
      setCropperOpen(true); // Open the cropper
    }
  };

  return (
    <Container>
      <Box
        sx={{
          mt: { xs: 2, md: 10 },
          pt: 4,
          pb: 4,
          backgroundColor: "#fff",
          borderRadius: 4,
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleBackToHome}
          sx={{ mt: 2, mx: 4 }}
        >
          {t("Back to home")}
        </Button>
        <Grid container spacing={4} alignItems="center">
          {/* Text Section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: { xs: 2, md: 4 } }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  mb: 3,
                  color: "#2e7d32",
                  fontSize: { xs: "24px", md: "36px" },
                }}
              >
                {t("imgtitle")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 4,
                  fontSize: { xs: "16px", md: "18px" },
                  lineHeight: 1.6,
                }}
              >
                {t("imgpart")}
              </Typography>
              <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Drop the image here...
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    Drag 'n' drop an image here, or click to select one
                  </Typography>
                )}
              </DropzoneContainer>

              <Box
                sx={{
                  mt: 2,
                  width: "100%",
                  maxWidth: "450px",
                  mx: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  label="Selected Image"
                  variant="outlined"
                  fullWidth
                  value={fileName}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ mt: 2 }}
                />
                <UploadButton
                  variant="contained"
                  component="label"
                  sx={{ mt: 2 }}
                >
                  {t("imageupload")}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </UploadButton>

                {/* Use the file input with capture attribute */}
                <UploadButton
                  variant="contained"
                  component="label"
                  sx={{ mt: 2, ml: { xs: 0, md: 2 } }}
                >
                  {t("Take a Picture")}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    capture="environment"
                    onChange={handleCapture}
                  />
                </UploadButton>
              </Box>

              {loading && (
                <CircularProgress
                  sx={{ mt: 4, display: "block", margin: "0 auto" }}
                />
              )}

              {croppedImage && (
                <ImagePreview>
                  <motion.img
                    src={croppedImage}
                    alt="Selected"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                </ImagePreview>
              )}

              {prediction && (
                <ResultsContainer>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {t("Prediction Result")}:
                    </Typography>
                    <Typography variant="body1">
                      {t("Disease")}: {prediction?.class}
                    </Typography>
                    <Typography variant="body1">
                      {t("Confidence")}: {prediction?.confidence}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {t("Recommendations")}:
                    </Typography>
                    {recommendation?.map((item, index) => (
                      <Typography key={index} variant="body1">
                        - {t(item)}
                      </Typography>
                    ))}
                  </motion.div>
                </ResultsContainer>
              )}

              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity={
                    snackbarMessage === "Image uploaded successfully!"
                      ? "success"
                      : "error"
                  }
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>

          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "center", p: { xs: 2, md: 4 } }}>
              <Grid container spacing={2} justifyContent="center">
                {/* Top Row with Two Images */}
                <Grid item xs={6} sm={6}>
                  <motion.img
                    src={UploadIllustration}
                    alt="Upload Illustration 1"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{
                      width: "100%",
                      borderRadius: "12px",
                      boxShadow: "0 0 15px rgba(0, 0, 0, 0.15)",
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <motion.img
                    src={UploadIllustration1}
                    alt="Upload Illustration 2"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{
                      width: "100%",
                      borderRadius: "12px",
                      boxShadow: "0 0 15px rgba(0, 0, 0, 0.15)",
                    }}
                  />
                </Grid>
                {/* Bottom Row with One Image */}
                <Box sx={{ textAlign: "center", p: 4 }}>
                  <video
                    width="100%" // Adjust the width as per your layout
                    height="400px" // Set the height to 400px or any value you need
                    src={UploadIllustration3} // Local video file
                    title="Vision for AI Agriculture"
                    autoPlay // Enables autoplay
                    muted // Mute the video (necessary for autoplay to work on some browsers)
                    loop // Optional: Loop the video continuously
                    controls // Enables video controls for user interaction
                    style={{
                      borderRadius: "12px",
                      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Cropper Dialog */}
      <Dialog
        open={cropperOpen}
        onClose={() => setCropperOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 400,
              background: "#333",
            }}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1} // You can adjust the aspect ratio
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, zoom) => setZoom(zoom)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCropperOpen(false)}>Cancel</Button>
          <Button onClick={showCroppedImage} variant="contained">
            Crop & Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

// Utility function to get the cropped image
async function getCroppedImg(imageSrc, croppedAreaPixels) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous"; // For cross-origin images
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      // Convert canvas to data URL
      const base64Image = canvas.toDataURL("image/jpeg");
      resolve(base64Image);
    };
    image.onerror = (error) => {
      reject(error);
    };
  });
}
