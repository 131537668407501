// src/App.js
import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import "./i18n"; // Initialize i18n for translations
import ImageUploadSection from "./components/ImageUploadSection";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AgriHyphenAIExplanation from "./components/AgriHyphenAIExplanation";
import Team from "./components/Team";
import Loader from "./components/Loader";

function LoaderWrapper({ isLoading, setIsLoading }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setIsLoading(true);
      // Simulate a loading delay, adjust it as needed
      setTimeout(() => {
        setIsLoading(false);
      }, 1900); // Adjust the timeout as per your requirement
    } else {
      setIsLoading(false);
    }
  }, [location, setIsLoading]);

  return isLoading ? (
    <Loader isLoading={isLoading} setIsLoading={setIsLoading} />
  ) : null;
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <BrowserRouter>
        {/* Loader is displayed only for the root path */}
        <LoaderWrapper isLoading={isLoading} setIsLoading={setIsLoading} />
        {!isLoading && (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<HeroSection />} />
              <Route path="/test-ai" element={<ImageUploadSection />} />
              <Route path="/info" element={<AgriHyphenAIExplanation />} />
              <Route path="/team" element={<Team />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
