import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Logo from "../image/New Hyphen Tech Logo (7).png";

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    handleCloseLanguageMenu();
  };

  const handleOpenLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <IconButton sx={{ float: "right" }}>
        <CloseIcon />
      </IconButton>
      <List>
        <ListItem button component="a" href="/">
          <ListItemText primary={t("nav1")} />
        </ListItem>
        <ListItem button component="a" href="/info">
          <ListItemText primary={t("nav2")} />
        </ListItem>
        <ListItem button component="a" href="/team">
          <ListItemText primary={t("nav3")} />
        </ListItem>
        <ListItem button component="a" href="/test-ai">
          <ListItemText primary="AgriHyphen Ai" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "green",
        backdropFilter: "blur(10px)",
        boxShadow: "none",
      }}
    >
      <Toolbar>
        {/* Brand Logo */}
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <img
            src={Logo} // Use your logo path here
            alt="Hyphen AI Logo"
            style={{
              height: "150px",
              width: "150px",
              position: "absolute",
              marginRight: "10px",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* Desktop Navigation */}
        {!isMobile && (
          <Box sx={{ display: "flex", gap: 2, mr: 4 }}>
            <Button color="inherit" href="/">
              {t("nav1")}
            </Button>
            <Button color="inherit" href="/info">
              {t("nav2")}
            </Button>
            <Button color="inherit" href="/team">
              {t("nav3")}
            </Button>
            <Button color="inherit" href="/test-ai">
              AgriHyphen Ai
            </Button>
          </Box>
        )}

        {/* Language Selector */}
        <IconButton onClick={handleOpenLanguageMenu} sx={{ color: "white" }}>
          <MdLanguage size={24} />
          <Typography variant="body1" sx={{ marginLeft: 1 }}>
            {t("Lang")}
          </Typography>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseLanguageMenu}
          sx={{ mt: 4 }}
          MenuListProps={{ "aria-labelledby": "language-button" }}
        >
          <MenuItem onClick={() => changeLanguage("en")}>English</MenuItem>
          <MenuItem onClick={() => changeLanguage("fr")}>Français</MenuItem>
        </Menu>

        {/* Mobile Menu Button */}
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        )}

        {/* Drawer for Mobile Navigation */}
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
