// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import fr from "./locales/fra.json";

// Function to get the default language
const getDefaultLanguage = () => {
  const deviceLanguage = navigator.language.split("-")[0]; // Get the language code
  const supportedLanguages = ["en", "fr"];
  return supportedLanguages.includes(deviceLanguage) ? deviceLanguage : "en";
};

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fr: { translation: fr },
  },
  lng: getDefaultLanguage(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
