import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { motion } from "framer-motion";
import Footer from "./PaymentSection";
import { useTranslation } from "react-i18next";

// Import images
import bubuyaImage from "../image/bubuya.jpeg";
import diegoImage from "../image/Diego.jpg";
import nancyImage from "../image/nancy.jpeg";
import jarryImage from "../image/jarry.jpg";
import elogeImage from "../image/eloge.png";

// Sample data for a team member
const teamMembers = [
  {
    name: "Elie Bubuya",
    role: "Machine Learning Engineer",
    image: bubuyaImage,
    description:
      "Elie Bubuya is the CEO and co-founder of Hyphen Tech, a machine learning engineer passionate about using technology to solve agricultural challenges. He studied machine learning in a workshop in Benin, with a strong focus on integrating AI into agriculture. Elie is deeply interested in the intersection of technology and the agricultural domain, and he leads the team in developing innovative solutions for farmers.",
  },
  {
    name: "Dieudonné Abanabimana",
    role: "Software engineer",
    image: diegoImage,
    description:
      "Abanabimana Dieudonne is the COO and co-founder of Hyphen Tech. He is a passionate software engineer with a deep love for informatics. His experience and enthusiasm for technology drive his commitment to developing powerful software solutions, and he works closely with the team to bring Agri-Hyphen AI to life.",
  },
  {
    name: "Nancy Kwezi",
    role: "Agronomist",
    image: nancyImage,
    description:
      "Kwezi Nancy is an agronomist with a keen interest in technology. She is the coordinator of the Libraries Without Borders program at Ngozi University and plays a critical role in bridging the gap between agriculture and technology in Agri-Hyphen AI. Her expertise in agronomy ensures that the solutions developed are highly relevant to farmers and agricultural stakeholders.",
  },
  {
    name: "Nigaba Jarry Hugue",
    role: "Economist and Market Analyst",
    image: jarryImage,
    description:
      "Jarry Hugue Nigaba provides economic insights and market analysis for AgriHyphen AI. His role is to conduct market research, ensuring the platform meets farmers’ needs and aligns with current economic trends.",
  },
  {
    name: "Abikunda Eloge",
    role: "Product and Marketing Manager",
    image: elogeImage,
    description:
      "Eloge Abikunda manages product development, marketing strategy, and distribution. He ensures that the AgriHyphen AI platform is effectively positioned in the market and reaches farmers and cooperatives.",
  },
];

const TeamMemberCard = ({ name, role, image, description }) => {
  const { t } = useTranslation();
  return (
    <motion.div
      whileHover={{
        scale: 1.05,
        rotateY: 15,
        boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.2)",
      }}
      transition={{ type: "spring", stiffness: 300 }}
      style={{
        perspective: 1000,
      }}
    >
      <Card
        sx={{ maxWidth: 345, m: 2, borderRadius: "16px", overflow: "hidden" }}
      >
        <CardMedia
          component="img"
          height="270"
          image={image}
          alt={`${t(name)}'s photo`}
          sx={{ objectFit: "cover" }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" align="center">
            {t(name)}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" align="center">
            {t(role)}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 1 }}
          >
            {t(description)}
          </Typography>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const Team = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, p: 8, backgroundColor: "#f1f1f1" }}>
      <Typography variant="h4" align="center" gutterBottom>
        {t("Our Team")}
      </Typography>
      <Grid container justifyContent="center" spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TeamMemberCard
              name={member?.name}
              role={member?.role}
              image={member?.image}
              description={member.description}
            />
          </Grid>
        ))}
      </Grid>
      <Footer />
    </Box>
  );
};

export default Team;
