import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "#f1f1f1",
        color: "#000000",
        marginTop: "auto",
        display: "flex",
        flexDirection: "column",
        py: 8,
      }}
    >
      <Container>
        {/* Test Our AI Button */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <Button
            variant="contained"
            color="primary"
            href="/test-ai"
            sx={{
              backgroundColor: "#ffffff",
              color: "#000000",
              px: 3,
              py: 1.5,
              fontSize: "1.2rem",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)", // Floating effect
              borderRadius: "8px",
              transform: "translateY(-3px)",
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out", // Smooth transition
              "&:hover": {
                transform: "translateY(-5px)", // Slight lift on hover
                boxShadow: "0 6px 15px rgba(0, 0, 0, 0.7)", // Enhanced shadow on hover
              },
            }}
          >
            {t("testOurAI")}
          </Button>
        </Box>

        {/* Contact Us Section */}
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          {t("contactUs")}
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          {t("email")}: info@hyphentech.tech | {t("phone")}: +257 62 15 32 72
        </Typography>

        {/* Card for Investors and Donators */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Card
            sx={{
              backgroundColor: "#1e1e1e",
              color: "#ffffff",
              maxWidth: 400,
              textAlign: "center",
              boxShadow: "0 4px 10px rgba(255, 255, 255, 0.3)", // Floating effect
              borderRadius: "8px",
              transition: "transform 0.2s ease-in-out", // Smooth transition
              "&:hover": {
                transform: "translateY(-5px)", // Slight lift on hover
                boxShadow: "0 6px 15px rgba(255, 255, 255, 0.5)", // Enhanced shadow on hover
              },
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t("joinUsAsInvestorOrDonator")}
              </Typography>
              <Typography variant="body2">
                {t("helpUsGrow")} {t("yourContributionsWillEnableUs")}{" "}
                {t("expandOurMission")}.
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                href="https://wa.me/+25779177940"
                target="_blank"
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  mx: 1,
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)", // Floating effect
                  borderRadius: "8px",
                  "&:hover": {
                    transform: "translateY(-3px)", // Slight lift on hover
                    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.7)", // Enhanced shadow on hover
                  },
                }}
              >
                {t("whatsapp")}
              </Button>
              <Button
                variant="outlined"
                href="mailto:info@hyphentech.tech"
                sx={{
                  color: "#ffffff",
                  borderColor: "#ffffff",
                  mx: 1,
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)", // Floating effect
                  borderRadius: "8px",
                  "&:hover": {
                    transform: "translateY(-3px)", // Slight lift on hover
                    boxShadow: "0 6px 15px rgba(255, 255, 255, 0.5)", // Enhanced shadow on hover
                  },
                }}
              >
                {t("Email")}
              </Button>
            </CardActions>
          </Card>
        </Box>

        {/* Social Media Icons */}
        {/* <Box sx={{ display: "flex", justifyContent: "center", gap: 2, my: 4 }}>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={30} style={{ color: "#000000" }} />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={30} style={{ color: "#000000" }} />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={30} style={{ color: "#000000" }} />
          </a>
        </Box> */}

        {/* Footer Links */}
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" align="center" gutterBottom>
              {t("aboutUs")}
            </Typography>
            <Typography variant="body2" align="center">
              {t("learnMoreAboutUs")}
              <br />
              <Button
                variant="contained"
                color="primary"
                href="https://www.hyphentech.tech"
                target="_blank"
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)", // Floating effect
                  borderRadius: "8px",
                  "&:hover": {
                    transform: "translateY(-3px)", // Slight lift on hover
                    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.7)", // Enhanced shadow on hover
                  },
                }}
              >
                hyphen Tech Webite
              </Button>
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Typography variant="h6" align="center" gutterBottom>
              {t("partners")}
            </Typography>
            <Typography variant="body2" align="center">
              {t("partnerWithUs")}
            </Typography>
          </Grid> */}
          {/* <Grid item xs={12} sm={4}>
            <Typography variant="h6" align="center" gutterBottom>
              {t("newsletter")}
            </Typography>
            <Typography variant="body2" align="center">
              {t("stayUpdated")}
            </Typography>
          </Grid> */}
        </Grid>

        {/* Copyright Section */}
        <Typography variant="body2" align="center" sx={{ mt: 10 }}>
          © {new Date().getFullYear()} {t("Hyphen Tech")}.{" "}
          {t("allRightsReserved")}
        </Typography>
      </Container>
    </Box>
  );
}
