import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Avatar,
} from "@mui/material";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ComputerIcon from "@mui/icons-material/Computer";
import CloudIcon from "@mui/icons-material/Cloud";
import LanguageIcon from "@mui/icons-material/Language";
import ScienceIcon from "@mui/icons-material/Science";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import Footer from "./PaymentSection";

const AgriHyphenAIExplanation = () => {
  const { t } = useTranslation(); // Access translation function

  return (
    <Container sx={{ mt: 10, py: 6, backgroundColor: "#f1f1f1" }}>
      {/* Header Section */}
      <Box textAlign="center" mb={6}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t("title1")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("subtitle")}
        </Typography>
      </Box>

      {/* How It Works - Sections */}
      <Grid container spacing={4}>
        {/* AI-Powered Disease Detection */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ height: "100%" }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar sx={{ bgcolor: "#4caf50", mr: 2 }}>
                  <AgricultureIcon />
                </Avatar>
                <Typography variant="h6">
                  {t("sections.diseaseDetection.title")}
                </Typography>
              </Box>
              <Typography variant="body1">
                {t("sections.diseaseDetection.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Precision Agriculture */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ height: "100%" }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar sx={{ bgcolor: "#3f51b5", mr: 2 }}>
                  <ComputerIcon />
                </Avatar>
                <Typography variant="h6">
                  {t("sections.precisionAgriculture.title")}
                </Typography>
              </Box>
              <Typography variant="body1">
                {t("sections.precisionAgriculture.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Future Enterprise Features */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ height: "100%" }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar sx={{ bgcolor: "#ff5722", mr: 2 }}>
                  <CloudIcon />
                </Avatar>
                <Typography variant="h6">
                  {t("sections.enterpriseFeatures.title")}
                </Typography>
              </Box>
              <Typography variant="body1">
                {t("sections.enterpriseFeatures.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Localization and Accessibility */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ height: "100%" }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                  <LanguageIcon />
                </Avatar>
                <Typography variant="h6">
                  {t("sections.localization.title")}
                </Typography>
              </Box>
              <Typography variant="body1">
                {t("sections.localization.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Model Expansion */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ height: "100%" }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar sx={{ bgcolor: "#673ab7", mr: 2 }}>
                  <ScienceIcon />
                </Avatar>
                <Typography variant="h6">
                  {t("sections.modelExpansion.title")}
                </Typography>
              </Box>
              <Typography variant="body1">
                {t("sections.modelExpansion.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Future Goals */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ height: "100%" }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar sx={{ bgcolor: "#ffeb3b", mr: 2 }}>
                  <AccessTimeIcon />
                </Avatar>
                <Typography variant="h6">
                  {t("sections.futureGoals.title")}
                </Typography>
              </Box>
              <Typography variant="body1">
                {t("sections.futureGoals.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default AgriHyphenAIExplanation;
