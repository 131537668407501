import React from "react";
import { Box, Typography, Container, Grid, Button } from "@mui/material";
import investorsImage from "../image/about2.jpg"; // Replace with the correct image path
import { useTranslation } from "react-i18next";
import Footer from "./PaymentSection";

const ProjectDescriptionSection = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ py: 0, backgroundColor: "#f1f1f1" }}>
      <Container>
        {/* Call to Action for Investors & Collaborators */}
        <Box sx={{ mt: 4, pt: 2, backgroundColor: "#fff", borderRadius: 4 }}>
          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 4 }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    mb: 3,
                    color: "#2e7d32",
                    fontSize: { xs: "24px", md: "36px" },
                  }}
                >
                  {t("joinUs")} {/* Translated join us text */}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    fontSize: { xs: "16px", md: "18px" },
                    lineHeight: 1.6,
                  }}
                >
                  {t("joinUsDescription")}{" "}
                  {/* Translated join us description */}
                </Typography>
                <Button
                  variant="contained"
                  href="https://wa.me/+25779177940"
                  target="_blank"
                  color="primary"
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: "16px",
                    backgroundColor: "#2e7d32",
                    "&:hover": { backgroundColor: "#1b5e20" },
                  }}
                >
                  {t("contactUs")} {/* Translated contact us text */}
                </Button>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center", p: 4 }}>
                <img
                  src={investorsImage}
                  alt="Investors"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "12px",
                    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default ProjectDescriptionSection;
