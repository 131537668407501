const recommendations = {
  Potato___Early_blight: {
    advice: [
      "recommendation.potatoEarlyBlight.disease",
      "recommendation.potatoEarlyBlight.step1",
      "recommendation.potatoEarlyBlight.step2",
      "recommendation.potatoEarlyBlight.step3",
      "recommendation.potatoEarlyBlight.step4",
      "recommendation.potatoEarlyBlight.step5",
      "recommendation.potatoEarlyBlight.step6",
    ],
  },
  Potato___Late_blight: {
    advice: [
      "recommendation.potatoLateBlight.disease",
      "recommendation.potatoLateBlight.step1",
      "recommendation.potatoLateBlight.step2",
      "recommendation.potatoLateBlight.step3",
      "recommendation.potatoLateBlight.step4",
      "recommendation.potatoLateBlight.step5",
      "recommendation.potatoLateBlight.step6",
    ],
  },
  Potato___healthy: {
    advice: [
      "recommendation.potatoHealthy.disease",
      "recommendation.potatoHealthy.step1",
      "recommendation.potatoHealthy.step2",
      "recommendation.potatoHealthy.step3",
      "recommendation.potatoHealthy.step4",
      "recommendation.potatoHealthy.step5",
      "recommendation.potatoHealthy.step6",
      "recommendation.potatoHealthy.step7",
      "recommendation.potatoHealthy.step8",
    ],
  },
  Tomato___Bacterial_spot: {
    advice: [
      "recommendation.tomatoBacterialSpot.disease",
      "recommendation.tomatoBacterialSpot.step1",
      "recommendation.tomatoBacterialSpot.step2",
      "recommendation.tomatoBacterialSpot.step3",
      "recommendation.tomatoBacterialSpot.step4",
      "recommendation.tomatoBacterialSpot.step5",
      "recommendation.tomatoBacterialSpot.step6",
    ],
  },
  Tomato___Early_blight: {
    advice: [
      "recommendation.tomatoEarlyBlight.disease",
      "recommendation.tomatoEarlyBlight.step1",
      "recommendation.tomatoEarlyBlight.step2",
      "recommendation.tomatoEarlyBlight.step3",
      "recommendation.tomatoEarlyBlight.step4",
      "recommendation.tomatoEarlyBlight.step5",
      "recommendation.tomatoEarlyBlight.step6",
    ],
  },
  Tomato___Late_blight: {
    advice: [
      "recommendation.tomatoLateBlight.disease",
      "recommendation.tomatoLateBlight.step1",
      "recommendation.tomatoLateBlight.step2",
      "recommendation.tomatoLateBlight.step3",
      "recommendation.tomatoLateBlight.step4",
      "recommendation.tomatoLateBlight.step5",
      "recommendation.tomatoLateBlight.step6",
    ],
  },
  Tomato___Leaf_Mold: {
    advice: [
      "recommendation.tomatoLeafMold.disease",
      "recommendation.tomatoLeafMold.step1",
      "recommendation.tomatoLeafMold.step2",
      "recommendation.tomatoLeafMold.step3",
      "recommendation.tomatoLeafMold.step4",
      "recommendation.tomatoLeafMold.step5",
      "recommendation.tomatoLeafMold.step6",
      "recommendation.tomatoLeafMold.step7",
    ],
  },
  Tomato___Septoria_leaf_spot: {
    advice: [
      "recommendation.tomatoSeptoriaLeafSpot.disease",
      "recommendation.tomatoSeptoriaLeafSpot.step1",
      "recommendation.tomatoSeptoriaLeafSpot.step2",
      "recommendation.tomatoSeptoriaLeafSpot.step3",
      "recommendation.tomatoSeptoriaLeafSpot.step4",
      "recommendation.tomatoSeptoriaLeafSpot.step5",
      "recommendation.tomatoSeptoriaLeafSpot.step6",
    ],
  },
  "Tomato___Spider_mites Two-spotted_spider_mite": {
    advice: [
      "recommendation.tomatoSpiderMites.disease",
      "recommendation.tomatoSpiderMites.step1",
      "recommendation.tomatoSpiderMites.step2",
      "recommendation.tomatoSpiderMites.step3",
      "recommendation.tomatoSpiderMites.step4",
      "recommendation.tomatoSpiderMites.step5",
      "recommendation.tomatoSpiderMites.step6",
      "recommendation.tomatoSpiderMites.step7",
      "recommendation.tomatoSpiderMites.step8",
    ],
  },
  Tomato___Target_Spot: {
    advice: [
      "recommendation.tomatoTargetSpot.disease",
      "recommendation.tomatoTargetSpot.step1",
      "recommendation.tomatoTargetSpot.step2",
      "recommendation.tomatoTargetSpot.step3",
      "recommendation.tomatoTargetSpot.step4",
      "recommendation.tomatoTargetSpot.step5",
    ],
  },
  Tomato___Tomato_Yellow_Leaf_Curl_Virus: {
    advice: [
      "recommendation.tomatoYellowLeafCurlVirus.disease",
      "recommendation.tomatoYellowLeafCurlVirus.step1",
      "recommendation.tomatoYellowLeafCurlVirus.step2",
      "recommendation.tomatoYellowLeafCurlVirus.step3",
      "recommendation.tomatoYellowLeafCurlVirus.step4",
      "recommendation.tomatoYellowLeafCurlVirus.step5",
      "recommendation.tomatoYellowLeafCurlVirus.step6",
    ],
  },
  Tomato___Tomato_mosaic_virus: {
    advice: [
      "recommendation.tomatoMosaicVirus.disease",
      "recommendation.tomatoMosaicVirus.step1",
      "recommendation.tomatoMosaicVirus.step2",
      "recommendation.tomatoMosaicVirus.step3",
      "recommendation.tomatoMosaicVirus.step4",
      "recommendation.tomatoMosaicVirus.step5",
      "recommendation.tomatoMosaicVirus.step6",
    ],
  },
  Tomato___healthy: {
    advice: [
      "recommendation.tomatoHealthy.disease",
      "recommendation.tomatoHealthy.step1",
      "recommendation.tomatoHealthy.step2",
      "recommendation.tomatoHealthy.step3",
      "recommendation.tomatoHealthy.step4",
      "recommendation.tomatoHealthy.step5",
      "recommendation.tomatoHealthy.step6",
      "recommendation.tomatoHealthy.step7",
      "recommendation.tomatoHealthy.step8",
    ],
  },
};

export default recommendations;
