import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { motion } from "framer-motion";
import { Box, Button, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import img1 from "../image/img7.jpg";
import img2 from "../image/img6.jpg";
import img3 from "../image/potatoes-in-garden-bed.jpg";
import img4 from "../image/tomato-greenhouse-farming.jpg";
import img5 from "../image/Agriculture-Sensor-Technology-Ritam-Gandhi.png";
import ProjectDescriptionSection from "./ProjectDescriptionSection";
import LandingPage from "./langingpage";
import AndroidEmulatorCard from "./AndroidEmulatorCard";

const images = [
  { src: img1, description: "hero.description1" },
  { src: img2, description: "hero.description2" },
  { src: img3, description: "hero.description4" },
  { src: img4, description: "hero.description5" },
  { src: img5, description: "hero.description2" },
];

const ArrowButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "white",
  fontSize: "30px",
  borderRadius: "50%",
  padding: "10px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
}));

const HeroSection = () => {
  const { t } = useTranslation(); // Use translation hook
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null); // Use a ref to store the interval ID

  // Handle Next and Previous Clicks
  const handleNext = () =>
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  const handlePrev = () =>
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );

  // Pause autoplay on user interaction
  const pauseAutoplay = () => {
    clearInterval(intervalRef.current); // Clear the interval when user interacts
  };

  // Resume autoplay after user interaction (with a delay)
  const resumeAutoplay = () => {
    clearInterval(intervalRef.current); // Clear the previous interval if any
    intervalRef.current = setInterval(handleNext, 8000); // Set autoplay
  };

  // useEffect to set up autoplay on mount
  useEffect(() => {
    resumeAutoplay(); // Start autoplay when component mounts

    return () => {
      clearInterval(intervalRef.current); // Clear interval on component unmount
    };
  }, []);

  // Handle user interaction with thumbnails
  const handleThumbnailClick = (index) => {
    pauseAutoplay(); // Pause autoplay on thumbnail click
    setCurrentIndex(index); // Set the clicked thumbnail as active
    setTimeout(resumeAutoplay, 10000); // Resume autoplay after 10 seconds
  };

  return (
    <Box
      sx={{
        py: { xs: 4, md: 8 },
        position: "relative",
        overflow: "visible",
        backgroundColor: "#f1f1f1",
      }}
    >
      <Box
        className="slider"
        sx={{
          position: "relative",
          height: { xs: "60vh", md: "100vh" },
          width: "100%",
        }}
      >
        {images.map((image, index) => (
          <motion.div
            key={index}
            className={`item ${index === currentIndex ? "active" : ""}`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: index === currentIndex ? 1 : 0,
              transition: "opacity 1s ease-in-out",
            }}
          >
            <img
              src={image.src}
              alt={t(image.title)}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "brightness(0.7)",
              }}
            />
            <Box
              className="content"
              sx={{
                position: "absolute",
                top: "5%",
                left: { xs: "4%", md: "6%" },
                color: "#fff",
                textShadow: "0px 0px 10px rgba(0, 0, 0, 0.7)",
                background: "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(10px)",
                borderRadius: "12px",
                padding: { xs: "10px 20px", md: "20px 40px" },
                maxWidth: { xs: "300px", md: "450px" },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "18px", md: "32px" },
                }}
              >
                {t(image.description)}
              </Typography>
            </Box>
          </motion.div>
        ))}
        <Box
          className="arrows"
          sx={{ position: "absolute", top: "50%", right: "30px", zIndex: 100 }}
        >
          <ArrowButton
            onClick={() => {
              pauseAutoplay(); // Pause on manual click
              handlePrev(); // Go to previous slide
              setTimeout(resumeAutoplay, 10000); // Resume autoplay after 10 seconds
            }}
          >
            {"<"}
          </ArrowButton>
          <ArrowButton
            onClick={() => {
              pauseAutoplay(); // Pause on manual click
              handleNext(); // Go to next slide
              setTimeout(resumeAutoplay, 10000); // Resume autoplay after 10 seconds
            }}
            sx={{ ml: 1 }}
          >
            {">"}
          </ArrowButton>
        </Box>

        {/* Thumbnail Section */}
        <Box
          className="thumbnail"
          sx={{
            position: "absolute",
            bottom: "-110px",
            left: "50%",
            zIndex: 100,
            display: "flex",
            gap: "10px",
            transform: "translateX(-50%)",
            flexWrap: "wrap",
            justifyContent: "center",
            width: { xs: "80%", md: "auto" },
          }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              className="item"
              onClick={() => handleThumbnailClick(index)} // Pause autoplay and switch on thumbnail click
              sx={{
                width: { xs: "90px", md: "150px" },
                height: { xs: "130px", md: "220px" },
                flexShrink: 0,
                position: "relative",
                cursor: "pointer",
                border: index === currentIndex ? "4px solid #4CAF50" : "none", // Highlight active thumbnail
                borderRadius: "20px",
              }}
            >
              <img
                src={image.src}
                alt={`Thumbnail ${index}`}
                style={{
                  top: "100px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                  boxShadow: "5px 0 15px rgba(0, 0, 0, 0.3)",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <LandingPage />
      <ProjectDescriptionSection />
    </Box>
  );
};

export default HeroSection;
